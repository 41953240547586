"use client";

import { ArrowPathIcon, FingerPrintIcon, ClipboardDocumentListIcon, BeakerIcon } from '@heroicons/react/24/outline';
import Image from "next/image";
import { Button } from './components/button';
import { useCallback, useEffect, useState } from 'react';
import { createClient } from '@/utils/supabase/client';
const features = [{
  name: 'Keep Aquariums Documented',
  description: 'Create a digital log of your aquariums. Add photos, notes, and more to keep track of your tanks.',
  icon: ClipboardDocumentListIcon
}, {
  name: 'Track Water Parameters',
  description: 'Record your water parameters and track changes over time. Get insights into how your tank is doing.',
  icon: BeakerIcon
}, {
  name: 'Keep Track of Your Fish',
  description: 'Keep a list of your fish and track their health and behavior.',
  icon: ArrowPathIcon
}, {
  name: 'Get reminders for feeding and maintenance',
  description: 'Set up reminders for feeding, water changes, and other maintenance tasks.',
  icon: FingerPrintIcon
}];
export default function Example() {
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const userSessionCheck = useCallback(async () => {
    const supabase = createClient();
    const {
      data: {
        session
      }
    } = await supabase.auth.getSession();
    if (session) {
      setUserLoggedIn(true);
    }
  }, []);
  useEffect(() => {
    userSessionCheck();
  }, [userSessionCheck]);
  return <div className="bg-white mx-auto max-w-5xl" data-sentry-component="Example" data-sentry-source-file="page.tsx">
            <div>
                <Image src="/images/fish_banner.png" alt="fish banner" width={1024} height={212} className="mx-auto" data-sentry-element="Image" data-sentry-source-file="page.tsx" />
            </div>
            <div className="mx-auto pt-6 px-6 lg:px-8">
                <div className="  lg:text-center">
                    <Image src="/images/logo.png" alt="Your Company" width={60} height={60} className="mx-auto h-20 w-auto" data-sentry-element="Image" data-sentry-source-file="page.tsx" />
                    <h2 className="text-base font-semibold leading-7 text-indigo-600">ReefRecord</h2>
                    <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        Keep your aquariums documented and healthy
                    </p>
                    <p className="mt-6 text-lg leading-8 text-gray-600">
                        ReefRecord is a digital logbook for your aquariums. Keep track of your tanks, fish, and water parameters. Get reminders for feeding and maintenance tasks.
                    </p>
                </div>
                <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                        {features.map(feature => <div key={feature.name} className="relative pl-16">
                                <dt className="text-base font-semibold leading-7 text-gray-900">
                                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                                        <feature.icon aria-hidden="true" className="h-6 w-6 text-white" />
                                    </div>
                                    {feature.name}
                                </dt>
                                <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
                            </div>)}
                    </dl>
                </div>
                {!userLoggedIn ? <div className="mx-auto max-w-7xl px-8 py-8 flex justify-end">
                        <Button href="/login">Sign In</Button>
                    </div> : <div className="mx-auto max-w-7xl px-8 py-8 flex justify-end">
                        <Button href="/dashboard">Go to Dashboard</Button>
                    </div>}
            </div>
        </div>;
}